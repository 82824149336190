import { HeaderMainSite } from "@cs/screens/_app/header"
import { GetLayout } from "types/page"

const noFooterLayout: GetLayout = page => (
  <>
    <HeaderMainSite />
    {page}
  </>
)
export default noFooterLayout
