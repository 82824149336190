import Image from "next/image";
import { Trans, useTranslation } from "next-i18next";
import { Text, UnderlineLink } from "@climate-science/component-library";
import ClimateScienceIcon
  from "@cs/public/climate-science/icons/climate-science-icon-192x192.png";

import styles from "./Custom404.module.scss";


const Custom404 = () => {
  const { t } = useTranslation("custom404");

  return (
    <div className={styles.container}>
      <Text textAlign="center" level="h1">
        {t("heading")}
      </Text>
      <hr />
      <Text textAlign="center" level="h2">
        <Trans
          t={t}
          i18nKey={"subheading"}
          components={[
            <UnderlineLink href={"/"} key="0" />
          ]}
        />
      </Text>
      <br />
      <Image src={ClimateScienceIcon} alt="Earthly logo" />
    </div>
  );
};

export default Custom404;
