// import React from "react";
import { GetStaticProps } from "next/types"
import nextI18nextConfig from "next-i18next.config"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Custom404 from "@cs/screens/_app/custom404"
import noFooterLayout from "../layouts/NoFooter.layout"

const Custom404Page = () => <Custom404 />
Custom404Page.getLayout = noFooterLayout

export default Custom404Page

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(
      locale ?? "en",
      ["custom404", "nav", "landing-page"],
      nextI18nextConfig,
    )),
  },
  // revalidate: 60 * 60 * 24
})
